<template>
  <div class="container">

    <div v-if="isLoading" class="row">
      <div class="text-center mt-5">
        <spinner/>
      </div>
    </div>

    <template v-else>
      <div class="row">
        <div class="col-md-8 mx-auto support-card">
          <h5>Contact Us</h5>
          <hr>

          <div>
            <form @submit.prevent="handleSubmit">
              <div class="mb-3">
                <label class="form-label" for="subject">Category</label>
                <select id="subject" v-model="userSubject" class="form-select" required>
                  <option v-for="(item, index) in categoryOptions" :key="index" :value="item">{{ item }}</option>
                </select>
              </div>

              <div class="mb-3">
                <label class="form-label" for="message">What can we do for you?</label>
                <textarea id="message" v-model="userBody" class="form-control" :maxlength="maxCount"
                           @keyup="updateCount"
                          name="text" placeholder="Type in your message" rows="5"></textarea>
                <span id="count_message" class="pull-right label label-default"> {{ count }} / {{ maxCount }} </span>
                <br>
              </div>


              <div>
                <button class="btn btn-green" type="submit">Send</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Spinner from "@/components/Spinner";
import {sendSupportMessage} from "@/store/services/accountService";

export default {
  name: "Support",
  components: {Spinner},

  computed: {
    ...mapGetters('accounts', ['getCurrentUser'])
  },

  data() {
    return {
      userSubject: '',
      userBody: '',
      isLoading: false,
      count: 0,
      maxCount: 512,

      categoryOptions: [
        'Subscription Issues',
        'Missing Article',
        'Request a Regulator',
        'Article Issue',
        'Account Inquiry',
        'Other',
      ]

    }
  },
  watch:{

  },
  methods: {
    updateCount(){
      var text_length = this.userBody.length;
      this.count = text_length
    },
    handleSubmit() {
      const subject = this.userSubject;
      const body = this.userBody;
      const currentEmail = this.getCurrentUser.email;

      this.isLoading = true;
      sendSupportMessage(currentEmail, subject, body)
          .then(response => {
            if (response.request.status === 201) {
              this.$store.dispatch('notifications/pushNotification', {
                message: 'Thanks! We will be in touch shortly',
                type: 'green'
              });

              this.$tracking.trackEvent('dashboardSupportFormSubmit', {
                email: currentEmail,
                subject: this.userSubject,
                body: this.userBody
              });

              this.userSubject = '';
              this.userBody = '';
              this.isLoading = false;

              this.$router.push({name: 'SupportConfirmation'});

            }
          });
    }
  }

}
</script>

<style lang="scss" scoped>
.support-card {
  margin-top: 3rem;
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  -webkit-transition: all .3s ease-in-out;
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 15px;
  padding: 1.5rem 2.1rem;
}

textarea {
  resize: none;
}

#count_message {
  background-color: gray;
  margin-top: 0px;
  margin-right: 0px;
  float: right;
  padding: 0px;
  width: 70px;
  color: white;
  text-align: center;
}
</style>
